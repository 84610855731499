var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-card-actions', {
    attrs: {
      "no-actions": true,
      "title": "List Barang Aset"
    }
  }, [_c('section', [_vm.allowCreate() ? _c('b-button', {
    staticClass: "mb-2 mr-2",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.$router.push({
          name: 'asset-add'
        });
      }
    }
  }, [_vm._v(" Tambah ")]) : _vm._e(), _vm.allowDelete() && _vm.showMassDelButton ? _c('b-button', {
    staticClass: "mb-2",
    attrs: {
      "variant": "outline-danger"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.confirmMassDelete($event);
      }
    }
  }, [_vm._v(" Hapus ")]) : _vm._e()], 1), _c('data-barang', {
    attrs: {
      "datas": _vm.items
    },
    on: {
      "refreshData": _vm.getData,
      "loading": _vm.toggleLoading
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }